import React, { Component } from 'react';
import axios from 'axios';
import Config from '../Config';
import '../ScrollTab.css';
import LazyLoad from 'react-lazyload';
import './ProjectShowcase.css';

class ProjectShowcase extends Component {
  state = {
    projectDomElements: []
  }

  componentDidMount() {
    axios.get(Config.apiUrl + '/images.php?project_id=' + 
      this.props.projectId).then(res => {
        this.setState({
          projectDomElements: res.data.map(obj =>
            <div key={obj.id}>
              <LazyLoad height={100}>
                <img className="ShowcaseImgFluid" src={obj.path}
                  alt={obj.description} />
              </LazyLoad>
            </div>
          )
        });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      axios.get(Config.apiUrl + '/images.php?project_id=' + 
        this.props.projectId).then(res => {
          this.setState({
            projectDomElements: res.data.map(obj =>
              <div key={obj.id}>
                <LazyLoad height={100}>
                  <img className="ShowcaseImgFluid" src={obj.path}
                    alt={obj.description} />
                </LazyLoad>
              </div>
            )
          });
      });
    }
  }

  render() {
    return(
      <div className="ShowcaseContentSection">
        {this.state.projectDomElements}
      </div>
    );
  }
}

export default ProjectShowcase;
