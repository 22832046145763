import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Frontpage from './Frontpage';
import Projectspage from './Projectspage';
import Aboutpage from './Aboutpage';

class MobileApp extends Component { 
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={Frontpage} />
          <Route exact path='/projects' component={Projectspage} />
          <Route exact path='/about' component={Aboutpage} />
        </Switch>
      </div>
    );
  }
}

export default MobileApp;
