import React, { Component } from 'react';
import './Navbar.css';
import logo from './img/logo.png';
import HamburgerMenu from './HamburgerMenu';
import { Link } from 'react-router-dom';

class Navbar extends Component { 
  render() {
    return (
      <div>
        <div className="NavbarContainer">
          <Link to="/" className="NavbarTitle">
            <img className="Logo" src={logo} alt="UNAING" />
          </Link>
          <div className="TextGrand">
            {this.props.children}
          </div>
          <HamburgerMenu>
            <Link to="/">UNAING</Link>
            <Link to="/projects">Projekti</Link>
            <Link to="/about">O Nama</Link>
          </HamburgerMenu>
        </div>
        <div className="PostNavbarSpacing" />
      </div>
    );
  }
}

export default Navbar;
