import React, { Component } from 'react';
import './SplitView.css';

class SplitView extends Component {
  render() {
    return (
      <div className="SplitView">
        <div className="SplitViewMaster">
          {this.props.master}
        </div>
        <div className="SplitViewDetails">
          {this.props.details}
        </div>
      </div>
    );
  }
}

export default SplitView;
