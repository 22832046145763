import React, { Component } from 'react';
import './HamburgerMenu.css';

class HamburgerMenu extends Component {
  state = {
    isOpen: false
  }
  constructor(props) {
    super(props);

    this.openMenu = this.openMenu.bind(this);
  }

  openMenu() {
    if (this.state.isOpen) {
      document.getElementsByClassName("HamburgerMenuContainer")[0]
        .classList.remove("HamburgerMenuContainerOpen");
      this.setState({isOpen: false});
    } else {
      document.getElementsByClassName("HamburgerMenuContainer")[0]
        .classList.add("HamburgerMenuContainerOpen");
      this.setState({isOpen: true});
    }
  }

  render() {
    return (
      <div className="HamburgerContainer">
        <div onClick={this.openMenu} className="HamburgerButton">
          <div className="HamburgerButtonBar" />
          <div className="HamburgerButtonBar" />
          <div className="HamburgerButtonBar" />
        </div>
        <div className="HamburgerMenuContainer">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default HamburgerMenu;
