import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Frontpage from './Frontpage';
import Aboutpage from './Aboutpage';
import Projectspage from './Projectspage';

class DesktopApp extends Component { 
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={Frontpage} />
          <Route exact path='/about' component={Aboutpage} />
          <Route exact path="/projects" component={Projectspage} />
          <Route exact path="/projects/:categoryId" component={Projectspage} />
          <Route exact path="/projects/:categoryId/:projectId" component={Projectspage} />
        </Switch>
      </div>
    );
  }
}

export default DesktopApp;
