import React, { Component } from 'react';
import './Frontpage.css';
import axios from 'axios';
import Config from './Config';
import './ScrollTab.css';
import ProjectShowcase from './ProjectShowcase';

class CategoryShowcase extends Component {
  state = {
    projectDomElements: []
  }

  componentDidMount() {
    axios.get(Config.apiUrl + '/projects.php?category_id=' + 
      this.props.categoryId).then(res => {
        this.setState({
          projectDomElements: res.data.map(obj =>
            <div key={obj.id}>
              <h3 className="TextGrand TextImportant TextCenter">
                {obj.title}
              </h3>
              <ProjectShowcase projectId={obj.id} />
            </div>
          )
        });
    });
  }

  render() {
    return (
      <div className="ContentSection">
        {this.state.projectDomElements}
      </div>
    );
  }
}

export default CategoryShowcase;
