import React, { Component } from 'react';
import '../Frontpage.css';
import projectsTitle from '../img/projectsTitle.svg';
import aboutTitle from '../img/aboutTitle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin } from
  '@fortawesome/free-brands-svg-icons'
import logo from '../img/logo.png';
import { Link } from 'react-router-dom';

class Frontpage extends Component { 
  render() {
    return (
      <div className="OuterCoverContainer Backgrounded">
        <div className="CoverContainer">
          <header className="CoverHeader">
            <div>
              <nav className="Navbar">
                <Link className="NavElementHuge" to="/projects">
                  <img className="ImgHuge" src={projectsTitle} 
                    alt="Projekti" />
                </Link>
                <Link className="NavElementHuge" to="/about">
                  <img className="ImgHuge" src={aboutTitle} 
                    alt="O Nama" />
                </Link>
              </nav>
            </div>
          </header>

          <main className="CoverContent">
            <Link to="/" className="CenterBlock ImgFluidMinimized">
              <img className="ImgFluid" src={logo} alt="UNAING" />
            </Link>
          </main>

          <footer className="CoverFooter FlexColumn">
            <div>
              <a className="SocialIconHuge" 
                  href="https://www.facebook.com/una.inzinjering.56">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a className="SocialIconHuge" href="https://www.instagram.com">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a className="SocialIconHuge" 
                  href="https://www.linkedin.com/company/una-ing">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
            <div>
              <a className="TextHuge" href="mailto:info@unaing.com">
                info@unaing.com
              </a>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Frontpage;
