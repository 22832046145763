import React, { Component } from 'react';
import '../Frontpage.css';
import aboutTitle from '../img/aboutTitle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin } from
  '@fortawesome/free-brands-svg-icons'
import SplitView from './SplitView';
import employees from '../img/employees.jpg';

class Aboutpage extends Component { 
  render() {
    return (
      <SplitView
        master={
        <div className="CenteredChildren">
          <img className="ImgHuge" src={aboutTitle} alt="Projekti" />
          <div className="SplitViewMasterBody BlockCenter">
            <div className="TextGrand TextCenter">
              <div>
                <span>Pravno Lice:</span> <span>Una Inžinjering</span>
              </div>
              <div>
                <span>Broj Pravnog Lica:</span> <span>4400781690000</span>
              </div>
              <div>
                <span>Datum Nastanka:</span> <span>08/02/2001</span>
              </div>
              <div>
                <span>Svrha:</span> <span>Arhitektonika</span>
              </div>
              <div>
                <span>Telefonski Broj:</span> <span>+387 51 231 900</span>
              </div>
              <div>
                <span>Faks:</span> <span>+387 51 231 902</span>
              </div>
              <div>
                <a href="mailto:info@unaing.com">info@unaing.com</a>
              </div>
              <div>
                <a className="TextGrand SocialIcon" 
                    href="https://www.facebook.com/una.inzinjering.56">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a className="TextGrand SocialIcon" 
                    href="https://www.instagram.com">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a className="TextGrand SocialIcon" 
                    href="https://www.linkedin.com/company/una-ing">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
          </div>
        </div>
        } details={
        <div>
          <div className="ContentPadded">
            <div className="FlexLRSplit">
              <div>
                <img className="ImgTiny" 
                  src={employees}
                  alt="Arhitekte Naše Kompanije" />
              </div>
              <div>
                <div className="ContentSection ContentSectionEmpty">
                  <p className="TextFocus TextLeft" style={{marginTop: "2px"}}>
                    UNAING je studio savremene arhitekture osnovan 2001. u 
                    Banjaluci.
                  </p>
                </div>

                <div className="ContentSection ContentSectionEmpty">
                  <p className="TextFocus TextLeft" style={{marginTop: "2px"}}>
                    Cilj nam je da razvijamo kulturu građenja. Bavimo se svim 
                    nivoima arhitektonsog projektovanja od detalja enterijera do 
                    izrade urbanističkih planova.
                  </p>
                </div>
              </div>
            </div>

            <div className="ContentSection" style={{padding: 0}}>
              <h2 className="TextFocus TextGrand" 
                  style={{marginBottom: "2px", fontSize: "1.5rem"}} >
                Gdje Nas Možete Naći?
              </h2>
              <div className="EmbedFluidShort">
                <iframe className="EmbedFluidItem" title="Our location"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAvNlFFGAIZu4c-cqPk9NX4f9jAWMIdhkY&q=Srpska+Ulica+99"
                  allowFullScreen />
              </div>
            </div>
          </div>
        </div>
      } />
    );
  }
}

export default Aboutpage;
