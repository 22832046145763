import React, { Component } from 'react';
import './Unafooter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin } from
  '@fortawesome/free-brands-svg-icons'

class Unafooter extends Component { 
  render() {
    return (
      <footer className="FooterContainer">
        <div><a href="mailto:info@unaing.com">info@unaing.com</a></div>
        <div>+387 51 231 900</div>
        <div>&copy; UNAING 2018</div>
        <div>
          <a className="TextGrand SocialIcon"
              href="https://www.facebook.com/una.inzinjering.56">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a className="SocialIcon" href="https://www.instagram.com">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a className="SocialIcon"
              href="https://www.linkedin.com/company/una-ing">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </footer>
    );
  }
}

export default Unafooter;
