import React, { Component } from 'react';
import './Frontpage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin } from
  '@fortawesome/free-brands-svg-icons'
import logoShadowed from './img/logoShadowed.png';
import DynamicBackground3 from './DynamicBackground3';
import background1 from './img/background1.jpg';
import background2 from './img/background2.jpg';
import background3 from './img/background3.jpg';
import { Link } from 'react-router-dom';

class Frontpage extends Component { 
  render() {
    return (
      <div className="CoverContainer">
        <DynamicBackground3 src={[
          background1,
          background2,
          background3
        ]} />
        <header className="CoverHeader">
          <div>
            <nav className="Navbar">
              <Link to="/projects" className="NavElement TextGrand">PROJEKTI</Link>
              <Link to="/about" className="NavElement TextGrand">
                O NAMA
              </Link>
            </nav>
          </div>
        </header>

        <main className="CoverContent">
          <Link to="/">
            <img className="ImgFluid" src={logoShadowed} alt="UNAING" />
          </Link>
        </main>

        <footer className="CoverFooter">
          <a className="TextGrand SocialIcon" 
              href="https://www.facebook.com/una.inzinjering.56">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a className="TextGrand SocialIcon" href="https://www.instagram.com">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a className="TextGrand SocialIcon" 
              href="https://www.linkedin.com/company/una-ing">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </footer>
      </div>
    );
  }
}

export default Frontpage;
