import React, { Component } from 'react';
import '../Frontpage.css';
import projectsTitle from '../img/projectsTitle.svg';
import { Link } from 'react-router-dom';
import SplitView from './SplitView';
import CategoriesList from './CategoriesList';
import ProjectsList from './ProjectsList';
import './ProjectsPage.css';
import ProjectShowcase from './ProjectShowcase';

class Projectspage extends Component {

  render() {
    if (this.props.match.path === '/projects') {
      return (
        <SplitView
          master={
            <div className="ProjectsSplitViewMaster">
              <img className="ProjectsHeadImage ImgHuge" 
                src={projectsTitle} alt="Projekti" />
                <CategoriesList />
            </div>
          }
          details={
            <div></div>
          } />
      );
    } else if (this.props.match.path === '/projects/:categoryId') {
      return (
        <SplitView
          master={
              <div className="ProjectsSplitViewMaster">
                <Link className="ProjectsHeadImage" to="/projects/">
                  <img className="ImgHuge" 
                    src={projectsTitle} alt="Projekti" />
                </Link>
                <ProjectsList
                      categoryId={this.props.match.params.categoryId} />
              </div>
          }
          details={
            <div>
            </div>
          } />
      );
    } else if (this.props.match.path === '/projects/:categoryId/:projectId') {
      return (
        <SplitView
          master={
              <div className="ProjectsSplitViewMaster">
                <Link className="ProjectsHeadImage" to="/projects/">
                  <img className="ImgHuge" 
                    src={projectsTitle} alt="Projekti" />
                </Link>
                <ProjectsList
                  categoryId={this.props.match.params.categoryId} />
              </div>
          }
          details={
            <ProjectShowcase projectId={this.props.match.params.projectId} />
          } />
      );
    }
  }
}

export default Projectspage;
