import React, { Component } from 'react';
import './Frontpage.css';
import Navbar from './Navbar';
import Unafooter from './Unafooter';
import axios from 'axios';
import Config from './Config';
import SwipeableViews from 'react-swipeable-views';
import './ScrollTab.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CategoryShowcase from './CategoryShowcase';

class Projectspage extends Component {
  state = {
    categoryDomElements: [],
  }

  componentDidMount() {
    axios.get(Config.apiUrl + '/categories.php')
      .then(res => {
        this.setState({
          categoryDomElements: res.data.map(obj => 
            <div className="ScrollPage" key={obj.id}>
              <div className="ScrollTabNav">
                <span className="TextGrand">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </span>
                <span className="TextGrand">{obj.title}</span>
                <span className="TextGrand">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </div>
              <div className="ScrollTabContent">
                <CategoryShowcase categoryId={obj.id} />
              </div>
            </div>
          )
        });
    });
  }

  render() {
    return (
      <div>
        <Navbar>Projekti</Navbar>
        <SwipeableViews>
          {this.state.categoryDomElements} 
        </SwipeableViews>
        <Unafooter />
      </div>
    );
  }
}

export default Projectspage;
