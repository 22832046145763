import React, { Component } from 'react';
import './DynamicBackground3.css';

class DynamicBackground3 extends Component {
  render() {
    return (
      <div className="BackgroundContainer">
        {/* eslint-disable */}
        <img src={this.props.src[0]} />
        <img src={this.props.src[1]} />
        <img src={this.props.src[2]} />
        {/* eslint-enable */}
      </div>
    );
  }
}

export default DynamicBackground3;
