import React, { Component } from 'react';
import './Frontpage.css';
import Navbar from './Navbar';
import Unafooter from './Unafooter';
import { Parallax } from 'react-parallax';
import background2 from './img/background2.jpg';
import background3 from './img/background3.jpg';
import employees from './img/employees.jpg';

class Aboutpage extends Component { 
  render() {
    return (
      <div>
        <Navbar>O Nama</Navbar>
        <div className="Content">
          <Parallax
              bgImage={background3}
              bgImageAlt="A Well Designed Building"
              strength={100} >
            <div className="ContentSection">
              <div className="FlexLRSplit">
                <img className="ImgFluidMicro" src={employees} alt="Our Employees" />
                <div>
                  <p className="TextFocus TextLeft" style={{marginLeft: "0.8rem"}}>
                    UNAING je studio savremene arhitekture osnovan 2001. u 
                    Banjaluci.
                  </p>
                </div>
              </div>
            </div>
          </Parallax>

          <div className="ContentSection ContentSectionEmpty">
            <p className="TextNormal">
              Cilj nam je da razvijamo kulturu građenja. Bavimo se svim 
              nivoima arhitektonsog projektovanja od detalja enterijera do 
              izrade urbanističkih planova.
            </p>
          </div>

          <Parallax
              bgImage={background2}
              bgImageAlt="A Well Designed Building"
              strength={100} >
            <div className="ContentSection">
              <h2 className="TextFocus TextGrand" 
                  style={{marginBottom: "2px", fontSize: "1.5rem"}} >
                Gdje Nas Možete Naći?</h2>
              <div className="EmbedFluid">
                <iframe className="EmbedFluidItem" title="Our location"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAvNlFFGAIZu4c-cqPk9NX4f9jAWMIdhkY&q=Srpska+Ulica+99"
                  allowFullScreen></iframe>
              </div>
            </div>
          </Parallax>
        </div>
        <Unafooter />
      </div>
    );
  }
}

export default Aboutpage;
