import React, { Component } from 'react';
import '../Frontpage.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Config from '../Config';
import './Animations.css';
import './SplitView.css';
import { CSSTransitionGroup } from 'react-transition-group';

class ProjectsList extends Component {
  state = {
    categoryDomElements: []
  }

  componentDidMount() {
    axios.get(Config.apiUrl + '/projects.php?category_id=' + 
      this.props.categoryId).then(res => {
        this.setState({
          categoryDomElements: res.data.map(obj => 
            <div key={obj.id}>
              <Link to={'/projects/' + this.props.categoryId + '/' + obj.id}>
                <span className="TextGrand">{obj.title}</span>
              </Link>
            </div>
          )
        });
    });
  }

  render() {
    return (
      <div className="SplitViewMasterBody">
        <div className="TextGrand TextRight">
          <CSSTransitionGroup
            transitionName="SlideFadeFromRight"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}>
            {this.state.categoryDomElements}
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

export default ProjectsList;
